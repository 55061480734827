
        $showMediaLabel: false;
        $showOverlayGrid: false;
        $useRem: true;
      

.section--our-vision {
  margin-bottom: fluid(rem(100), rem(150));
  
  .section__image-column {
    grid-column: 1 / span 7;
  }

  .section__image-container {
    aspect-ratio: 800 / 643;
    background: color(miako, pink);

    svg {
      top: 25%;
      left: -40%;
      transform: scaleX(-1) rotate(13deg);
      width: 117%;
    }
  }

  .section__text-column {
    grid-column: 9 / span 3;

    @include mqBetween(tablet, desktop-xs) {
      grid-column: 9 / span 4;
    }
  }

  .section__text-container {
    margin-top: 33%;
  }
}
