
        $showMediaLabel: false;
        $showOverlayGrid: false;
        $useRem: true;
      

@media print {
  @page {
    margin: 1cm;
  }

  body {
    background: #fff;
    color: #000;
  }

  a {
    page-break-inside: avoid;
  }

  blockquote {
    page-break-inside: avoid;
  }

  table,
  pre {
    page-break-inside: avoid;
  }

  ul, ol, dl  {
    page-break-before: avoid;
  }
}
