
        $showMediaLabel: false;
        $showOverlayGrid: false;
        $useRem: true;
      

.grid {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  gap: fluid(rem(10), rem(40));
  
  @include mqBetween(phone-xs, phone-m) {
    grid-template-columns: minmax(0, 1fr);
    gap: rem(50) 0;
  }
}
