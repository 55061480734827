
        $showMediaLabel: false;
        $showOverlayGrid: false;
        $useRem: true;
      

.firework {
  position: fixed;
  z-index: 1;

  &--absolute {
    position: absolute;
  }

  &__bubble {
    position: absolute;
    z-index: 1;
    transform: translateY(-50%);
    width: fluid(rem(12), rem(21));
    background: color(miako, purple);
    border-radius: 100%;
    aspect-ratio: 1;

    &-container {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      transform-origin: 0 0;
    }
  }

  &__ring {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
    border: rem(2) solid color(miako, purple);
    border-radius: 100%;
  }
}
