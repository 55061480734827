
        $showMediaLabel: false;
        $showOverlayGrid: false;
        $useRem: true;
      

.container {
  max-width: rem(1800);
  min-width: rem(320);
  margin: 0 auto;
  padding: 0 fluid(rem(40), rem(200));

  @include mqBetween(phone-xs, phone-m) {
    padding: 0 rem(30);
  }
}
