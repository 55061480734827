
        $showMediaLabel: false;
        $showOverlayGrid: false;
        $useRem: true;
      

.section--give-money-support-passion {
  margin-bottom: fluid(rem(100), rem(250));

  .section__image-column {
    grid-column: 1 / span 7;

    @include mqOnly(tablet) {
      grid-column: 1 / span 6;
    }
  }

  .section__image-container {
    aspect-ratio: 800 / 643;
    background: color(miako, blue-green);

    svg {
      top: -61%;
      left: -19%;
      transform: rotate(-121deg);
      width: 117%;
    }
  }

  .section__text-column {
    grid-column: 9 / span 3;

    @include mqOnly(desktop-xs) {
      grid-column: 9 / span 4;
    }

    @include mqOnly(tablet) {
      grid-column: 8 / span 5;
    }
  }

  .section__text-container {
    margin-top: 40%;

    @include mqOnly(tablet) {
      margin-top: 10%;
    }
  }

  .section__heading {
    @include mqUp(desktop-s) {
      width: 130%;
    }
  }
}
