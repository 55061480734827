
        $showMediaLabel: false;
        $showOverlayGrid: false;
        $useRem: true;
      

.footer {
  position: relative;
  z-index: 1;
  width: 100%;

  @include mqBetween(phone-xs, phone-m) {
    margin-top: rem(75);
  }

  &__text-column {
    grid-column: 1 / span 12;
    grid-row: 1;
    margin-bottom: fluid(rem(150), rem(250));

    @include mqOnly(tablet) {
      margin-bottom: rem(120);
    }
    
    @include mqBetween(phone-xs, phone-m) {
      align-self: flex-end;
      margin-bottom: rem(75);
    }
  }

  &__text-tagline {
    position: relative;
    z-index: 1;

    span {
      font-weight: 500;
    }

    strong {
      font-family: font-family(alternate);

      // i {
      //   &:nth-child(1) {
      //     color: color(miako, purple);
      //   }

      //   &:nth-child(2) {
      //     color: color(miako, red);
      //   }

      //   &:nth-child(3) {
      //     color: color(miako, orange-red);
      //   }

      //   &:nth-child(4) {
      //     color: color(miako, yellow);
      //   }

      //   &:nth-child(5) {
      //     color: color(miako, green);
      //   }
      // }
    }

    i {
      display: inline-block;

      &:nth-child(2),
      &:nth-child(4) {
        clip-path: inset(0.425em 0 0 0); // inset(rem(22) 0 0 0);
      }
    }

  }

  &__footnote-column {
    grid-column: 1 / span 12;
    grid-row: 2;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between;
    gap: rem(25) rem(40);
    margin: auto 0 rem(45);

    @include mqOnly(tablet) {
      margin-bottom: rem(35);
    }

    @include mqBetween(phone-xs, tablet) {
      flex-direction: column-reverse;
    }

    @include mqBetween(phone-xs, phone-m) {
      margin-bottom: rem(30);
    }

    a,
    button {
      transition: none;
    }
  }

  &__footnote-text,
  &__footnote-links {
    font-size: rem(12);
    line-height: 165%;

    @include mqBetween(phone-xs, phone-m) {
      font-size: rem(11);
    }
  }

  &__footnote-text br {
    @include mqUp(phone-m) {
      display: none;
    }
  }

  &__footnote-links {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: rem(15) rem(30);
  }
}
