
        $showMediaLabel: false;
        $showOverlayGrid: false;
        $useRem: true;
      

.support {
  position: fixed;
  inset: auto 0 0;
  z-index: 1;
  background: color(black);
  color: color(white);

  &__container {
    padding: rem(32) 0;

    @include mqBetween(phone-xs, tablet) {
      padding: rem(25) 0;
    }
  }

  &__text {
    font-size: rem(12);
    line-height: 165%;

    @include mqOnly(phone-m) {
      font-size: rem(11);
    }
  
    @include mqBetween(phone-xs, phone-s) {
      font-size: rem(9.5);
    }

    a {
      display: inline-flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: flex-start;
      gap: 0 rem(6);
      transition: opacity 0.3s;

      @include mqBetween(phone-xs, phone-m) {
        gap: 0 rem(4);
      }

      svg {
        transform: translateY(rem(-1));
      }

      &:hover {
        opacity: 0.6;
      }
    }

    // a {
    //   text-decoration-color: color(white, null, 0);
    //   text-underline-offset: rem(4);
    //   transition: text-decoration 0.3s;

    //   &:hover {
    //     text-decoration: underline;
    //     text-decoration-color: color(white, null, 0.4);
    //   }
    // }
  }
}
