
        $showMediaLabel: false;
        $showOverlayGrid: false;
        $useRem: true;
      

.section--eco-friendly {
  margin-bottom: fluid(rem(100), rem(200));

  .section__image-column {
    grid-column: 2 / span 6;

    @include mqOnly(tablet) {
      grid-column: 1 / span 6;
    }
  }

  .section__image-container {
    aspect-ratio: 680 / 643;
    background: color(miako, orange-red);

    svg {
      top: 23%;
      left: -61%;
      transform: scaleX(-1) rotate(19deg);
      width: 137%;
    }
  }

  .section__text-column {
    grid-column: 9 / span 3;

    @include mqOnly(desktop-xs) {
      grid-column: 9 / span 4;
    }

    @include mqOnly(tablet) {
      grid-column: 8 / span 5;
    }
  }

  .section__text-container {
    margin-top: 5%;

    @include mqBetween(tablet, desktop-xs) {
      margin-top: 15%;
    }
  }
}
