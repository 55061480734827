
        $showMediaLabel: false;
        $showOverlayGrid: false;
        $useRem: true;
      

.loader {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  position: fixed;
  inset: 0;
  z-index: 4;
  opacity: 1;
  pointer-events: none;
  transition: opacity 0.3s;
  
  // p {
  //   font-size: fluid(rem(40), rem(120));
  //   line-height: 123%;
  // }

  &--hidden {
    opacity: 0;
  }
}

.body--loaded .loader {
  display: none;
}
