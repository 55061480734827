
        $showMediaLabel: false;
        $showOverlayGrid: false;
        $useRem: true;
      

h1,
h2,
h3 {
  font-family: font-family(default);
  font-weight: 700;
  line-height: 123%;
  // font-kerning: none;
  // text-rendering: optimizeSpeed;
}

h1 {
  font-size: fluid(rem(48), rem(71));
  // font-size: rem(94);
  // font-size: rem(59);

  @include mqBetween(phone-xs, phone-m) {
    font-size: rem(36);
  }
}

h2 {
  font-size: fluid(rem(38), rem(54));

  @include mqBetween(phone-xs, phone-m) {
    font-size: rem(31);
  }
}

h3 {
  margin-bottom: fluid(rem(15), rem(30));
  font-size: fluid(rem(32), rem(44));

  @include mqBetween(phone-xs, phone-m) {
    margin-bottom: rem(12);
    font-size: rem(29);
  }
}

p {
  font-family: font-family(default);
  font-size: fluid(rem(17), rem(21));
  font-weight: 400;
  line-height: 165%;
}

ul,
ol {
  font-size: fluid(rem(16), rem(21));
  font-weight: 400;
  line-height: 165%;
}
