
        $showMediaLabel: false;
        $showOverlayGrid: false;
        $useRem: true;
      

.section--trust-and-simplicity {
  margin-bottom: fluid(rem(150), rem(300));

  .section__image-column {
    grid-column: 2 / span 6;
  }

  .section__image-container {
    aspect-ratio: 680 / 643;
    background: color(miako, yellow);

    svg {
      top: -35%;
      left: -59%;
      transform: scaleX(-1) rotate(-47deg);
      width: 137%;
    }
  }

  .section__text-column {
    grid-column: 9 / span 4;
  }

  .section__text-container {
    margin-top: 50%;

    @include mqBetween(tablet, desktop-xs) {
      margin-top: 25%;
    }
  }
}
