
        $showMediaLabel: false;
        $showOverlayGrid: false;
        $useRem: true;
      

.section--social-responsibility {
  margin-bottom: fluid(rem(150), rem(300));

  .section__image-column {
    grid-column: 6 / span 5;

    @include mqOnly(desktop-xs) {
      grid-column: 7 / span 5;
    }

    @include mqOnly(tablet) {
      grid-column: 8 / span 5;
    }
  }

  .section__image-container {
    aspect-ratio: 561 / 560;
    background: color(miako, blue);

    svg {
      top: 59%;
      left: -92%;
      transform: rotate(-73deg);
      width: 227%;
    }
  }

  .section__text-column {
    grid-column: 1 / span 4;

    @include mqOnly(desktop-xs) {
      grid-column: 1 / span 5;
    }

    @include mqOnly(tablet) {
      grid-column: 2 / span 5;
    }
  }

  .section__text-container {
    margin-top: 35%;

    @include mqBetween(tablet, desktop-xs) {
      margin-top: 15%;
    }
  }

  .section__heading {
    @include mqUp(tablet) {
      max-width: 80%;
    }
  }
}
