
        $showMediaLabel: false;
        $showOverlayGrid: false;
        $useRem: true;
      

html,
body {
  position: relative;
  // height: 100%;
  font-size: 100%;
  scrollbar-width: thin;
}

html {
  min-height: 100%;
  background: color(white);
  // background: color(gray, scrollbar);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
}

body {
  min-height: 100svh;
  margin: 0;
  padding: 0;
  overflow: hidden auto;
  background: color(white);
  color: color(black);
  font-family: font-family(default);
  font-size: 100%;
  line-height: 1;
  text-align: left;
  text-size-adjust: none;
  scroll-behavior: smooth;

  * {
    box-sizing: border-box;

    &:focus,
    &::before,
    &::after {
      box-sizing: border-box;
    }
  }
}

@import 'button';
@import 'input';
@import 'typography';
