
        $showMediaLabel: false;
        $showOverlayGrid: false;
        $useRem: true;
      

::-webkit-scrollbar {
	width: rem(8);
  height: rem(8)
}

::-webkit-scrollbar-track {
  background: color(gray, scrollbar);
}

::-webkit-scrollbar-thumb {
	background: darken(color(gray, scrollbar), 20%);

	&:hover {
		background: darken(color(gray, scrollbar), 30%);
	}
}
