
        $showMediaLabel: false;
        $showOverlayGrid: false;
        $useRem: true;
      

.section {
  width: 100%;

  &__heading-column,
  &__image-column,
  &__text-column {
    grid-row: 1;
  }
}

.section.section--details {
  @include mqBetween(phone-xs, phone-m) {
    margin-bottom: rem(50);
  }

  .section__image-column {
    @include mqBetween(phone-xs, phone-m) {
      grid-column: 1;
      margin: 0 rem(-12);
    }
  }

  .section__image-container {
    position: relative;
    z-index: 1;
    overflow: hidden;
    border-radius: fluid(rem(40), rem(100));
  }

  .section__image {
    position: absolute;
    inset: 0;
    z-index: 1;
  }

  .section__image svg {
    position: absolute;
    z-index: 1;
    max-width: none;
    height: auto;
  }

  .section__text-column {
    @include mqBetween(phone-xs, phone-m) {
      grid-column: 1;
      grid-row: 2;
      padding: 0 rem(15);
    }
  }

  .section__text-container {
    @include mqBetween(phone-xs, phone-m) {
      margin: 0;
    }
  }

  .section__text {
    font-kerning: none;
  }

  + .section--info {
    @include mqBetween(phone-xs, phone-m) {
      margin-top: rem(25);
    }
  }
}

.section.section--info {
  margin-bottom: fluid(rem(150), rem(300));

  @include mqBetween(phone-xs, phone-m) {
    margin: 0 rem(-4) rem(75);
  }
  
  .section__heading-column {
    grid-column: 1 / span 12;
  }

  .section__heading {
    font-kerning: none;

    // @include mqBetween(phone-xs, phone-m) {
    //   text-align: center;
    // }

    i {
      font-weight: 500;
    }
  }
}

@import 'eco-friendly';
@import 'fun-stuff';
@import 'give-money-support-passion';
@import 'make-money-save-money';
@import 'our-mission';
@import 'our-vision';
@import 'social-responsibility';
@import 'trust-and-simplicity';
