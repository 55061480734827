
        $showMediaLabel: false;
        $showOverlayGrid: false;
        $useRem: true;
      

@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:wght@700&family=Montserrat:wght@400;500;600;700&display=swap');

$fonts: (
  default: (
    'Montserrat',
    'Arial',
    sans-serif,
  ),
  alternate: (
    'Montserrat Alternates',
    'Arial',
    sans-serif,
  ),
);

@function font-family($key) {
  @return map-get($fonts, $key);
}
