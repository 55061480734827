
        $showMediaLabel: false;
        $showOverlayGrid: false;
        $useRem: true;
      

.layout {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  z-index: 2;
  background: color(white);
}

@import 'body';
@import 'cover';
@import 'disclaimers';
@import 'footer';
@import 'header';
@import 'loader';
@import 'main';
@import 'support';
