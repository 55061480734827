
        $showMediaLabel: false;
        $showOverlayGrid: false;
        $useRem: true;
      

.cover {
  position: fixed;
  inset: 0;
  z-index: 3;
  background: color(white);
  pointer-events: none;
}

.body--loaded .cover {
  display: none;
}
