
        $showMediaLabel: false;
        $showOverlayGrid: false;
        $useRem: true;
      

.section--fun-stuff {
  margin-bottom: fluid(rem(100), rem(150));

  .section__image-column {
    grid-column: 6 / span 5;
  }

  .section__image-container {
    aspect-ratio: 561 / 643;
    background: color(miako, orange-yellow);

    svg {
      top: 76%;
      left: 30%;
      width: 157%;
    }
  }

  .section__text-column {
    grid-column: 1 / span 4;
  }

  .section__text-container {
    margin-top: 35%;
  }
}
