
        $showMediaLabel: false;
        $showOverlayGrid: false;
        $useRem: true;
      

.header {
  position: relative;
  z-index: 1;
  width: 100%;
  max-height: 97svh;
  min-height: 66svh;
  margin-bottom: fluid(rem(150), rem(250));
  overflow: hidden;
  aspect-ratio: 1;
  color: color(white);

  @include mqBetween(phone-xs, phone-m) {
    height: 98svh;
    max-height: rem(820);
    margin-bottom: rem(75);
  }

  .container,
  .grid {
    height: 100%;
  }

  &__heading-column {
    grid-row: 1;
    text-align: center;

    @include mqOnly(tablet) {
      margin-bottom: rem(240);
    }

    @include mqUp(tablet) {
      grid-column: 2 / span 10;
    }

    @include mqBetween(phone-xs, phone-m) {
      margin-bottom: 31svh;
    }

    // &--tagline {
    //   grid-column: 1 / span 12;
    //   gap: 0 rem(10);
  
    //   h1 {
    //     font-family: font-family(alternate);
    //     clip-path: inset(0.425em 0 0 0); // inset(rem(40) 0 0 0);
    //   }
  
    //   h2 {
    //     margin-top: rem(6);
    //     white-space: nowrap;
    //   }
  
    //   span {
    //     font-weight: 500;
    //   }
    // }
  }

  &__heading-container {
    position: relative;
    z-index: 1;
    height: 100%;
  }

  &__heading {
    position: absolute;
    inset: 50% 0 auto;
    z-index: 1;
    transform: translateY(-50%);
    font-kerning: none;

    // &--mission {
    //   display: none;
    // }

    &--tagline {
      opacity: 0;

      @include mqUp(tablet) {
        margin: 0 -10%;
      }

      span {
        font-weight: 500;
      }
  
      strong {
        display: inline-block;
        transform: translateY(0.07em);
        font-family: font-family(alternate);
        font-size: 144%;
      }
  
      i {
        display: inline-block;

        &:nth-child(2),
        &:nth-child(4) {
          clip-path: inset(0.242em 0 0 0);
        }
      }
    }
  }

  &__background {
    position: absolute;
    inset: 0;
    z-index: -1;

    svg {
      width: 100%;
      height: 100%;
      max-width: none;

      g {
        transform-origin: 50% 0;
      }
    }
  }

  &__pin-spacer {
    width: 100%;
    height: 112svh;
  }
}
