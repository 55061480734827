
        $showMediaLabel: false;
        $showOverlayGrid: false;
        $useRem: true;
      

.disclaimers {
  height: 0;
  overflow: hidden;
  background: color(gray, scrollbar);  

  &__grid {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    align-items: flex-start;
  }
}

.disclaimer {
  grid-column: 1;
  grid-row: 1;
  height: 0;
  overflow: hidden;
  opacity: 0;

  h1,
  h2,
  p,
  ul {
    font-weight: 400;
    line-height: 165%;
  }

  h1 {
    font-size: rem(16);
  }

  h2,
  p,
  ul {
    font-size: rem(13);

    @include mqBetween(phone-xs, phone-m) {
      font-size: rem(11);
    }
  }

  h1,
  h2,
  strong {
    font-weight: 600;
  }

  h1 + p {
    margin-bottom: 1.2em;
  }

  p + h2,
  ul + h2 {
    margin-top: 0.5em;
  }

  li {
    padding-left: 1em;

    &::before {
      content: '- ';
      // content: '— ';
      margin-left: -0.75em;
    }
  }

  small {
    font-size: 80%;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 0 rem(40);
    padding: rem(80) 0;
    transition: margin 0.3s;

    @include mqOnly(tablet) {
      padding: rem(50) 0;
    }

    @include mqBetween(phone-xs, tablet) {
      grid-template-columns: minmax(0, 1fr);
    }

    @include mqBetween(phone-xs, phone-m) {
      padding: rem(30) 0;
    }
  }
  
  &__column {
    grid-column: span 1;
  }
}

.disclaimers--active,
.disclaimer--active {
  height: auto;
  visibility: visible;

  // + .disclaimer--active .disclaimer__grid {
  //   margin-top: rem(-80);

  //   @include mqOnly(tablet) {
  //     margin-top: rem(-50);
  //   }

  //   @include mqBetween(phone-xs, phone-m) {
  //     margin-top: rem(-30);
  //   }
  // }
}
