
        $showMediaLabel: false;
        $showOverlayGrid: false;
        $useRem: true;
      

.body {
  overflow: hidden;
}

.body--a11y {
  *:focus {
    outline: rem(5) solid color(miako, yellow);
    outline-offset: rem(5);
  }

  // TODO: Support for checkboxes - add later if needed
  // .layout--a11y {
  //   .form__element--box:focus-within .form__element-box {
  //     z-index: 2;
  //     outline: rem(2) solid color(yellow--default);
  //     outline-offset: rem(10);
  //   }
  // }
}

.body--loaded {
  overflow: hidden auto;
}
