
        $showMediaLabel: false;
        $showOverlayGrid: false;
        $useRem: true;
      

.section--make-money-save-money {
  margin-bottom: fluid(rem(75), rem(150));

  .section__image-column {
    grid-column: 7 / span 5;
  }

  .section__image-container {
    aspect-ratio: 560 / 974;
    background: color(miako, green);

    svg {
      top: 7%;
      left: 31%;
      transform: scaleX(-1);
      width: 167%;
    }
  }

  .section__text-column {
    grid-column: 2 / span 4;

    @include mqOnly(tablet) {
      grid-column: 1 / span 5;
    }
  }

  .section__text-container {
    margin-top: 70%;

    @include mqBetween(tablet, desktop-xs) {
      margin-top: 40%;
    }
  }

  .section__heading {
    @include mqUp(desktop-xs) {
      max-width: 80%;
    }
  }
}
