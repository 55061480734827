
        $showMediaLabel: false;
        $showOverlayGrid: false;
        $useRem: true;
      

.main {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  z-index: 1;
  width: 100%;
  background: color(white);
}
