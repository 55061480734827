
        $showMediaLabel: false;
        $showOverlayGrid: false;
        $useRem: true;
      

.section--our-mission {
  margin-bottom: fluid(rem(150), rem(300));
  
  .section__image-column {
    grid-column: 7 / span 6;
  }

  .section__image-container {
    aspect-ratio: 680 / 990;
    background: color(miako, red);

    svg {
      top: 17%;
      left: 27%;
      width: 137%;
    }
  }

  .section__text-column {
    grid-column: 1 / span 5;

    @include mqOnly(tablet) {
      grid-column: 2 / span 4;
    }
  }

  .section__text-container {
    margin-top: 50%;
  }
}
